import React, { useEffect, useMemo, useState } from "react"
import Layout from "@components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

//images
import { getImage, StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import CurrencyFormatter from "../util/CurrencyFormatter"
import getStripe from "../util/stripejs"

const Donate = ({ location, data }) => {
  const donateBgImg = getImage(data.donateBg)

  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("one_time")
  const [product, setProduct] = useState(null)
  const [otherAmount, setOtherAmount] = useState("")
  const [email, setEmail] = useState("")
  const [errMsg, setErrMsg] = useState({ type: "", msg: "" })
  const [honor, setHonor] = useState(false)
  const [honorName, setHonorName] = useState("")
  const [fee, setFee] = useState(false)

  const alldata = useMemo(() => {
    const allStripePrice = data.allStripePrice.nodes.filter(
      el => el.type === type
    )
    setProduct(null)

    return allStripePrice
  }, [type])

  useEffect(() => {
    if (product) setOtherAmount("")
  }, [product])

  useEffect(() => {
    if (otherAmount) setProduct(null)
  }, [otherAmount])

  const redirectToCheckout = async event => {
    // event.preventDefault()
    if (!otherAmount && !product) {
      setErrMsg({
        type: "alert alert-danger",
        msg: "Please select amount or enter other amount",
      })
      return
    } else if (!email) {
      setErrMsg({
        type: "alert alert-danger",
        msg: "Please enter your email address",
      })
      return
    } else if (honor) {
      if (!honorName) {
        setErrMsg({
          type: "alert alert-danger",
          msg: "Please enter your honoree",
        })
        return
      }
    }

    setErrMsg({ type: "", msg: "" })
    setLoading(true)

    let mode = "payment"
    let donation_amount = product?.unit_amount / 100 || +otherAmount || 0
    let unit_amount = donation_amount * 100 || 0

    if (fee) {
      const totalAmount = (donation_amount + 0.3) / (1 - 0.029)
      unit_amount = +totalAmount.toFixed(2) * 100
    }

    let line_items = [
      {
        price_data: {
          currency: "usd",
          product_data: {
            name: "Donation",
          },
          unit_amount,
        },
        quantity: 1,
      },
    ]

    if (type === "recurring") {
      mode = "subscription"

      if (!product || fee) {
        line_items = [
          {
            ...line_items[0],
            price_data: {
              ...line_items[0].price_data,
              recurring: {
                interval: "month",
              },
            },
          },
        ]
      }
    }

    if (product && !fee) line_items = [{ price: product.id, quantity: 1 }]

    let payload = {
      mode,
      email,
      line_items,
      metadata: { honoree: honorName, fee },
    }
    const response = await fetch(
      "/.netlify/functions/create-checkout-session",
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    ).then(res => res.json())

    if (response.success) {
      if (response?.customer?.data?.length) {
        setErrMsg({
          type: "alert alert-info",
          msg:
            "We've found out that you are already a registered partner, we have sent an email with the steps to follow in order for you to continue donating, thank you so much for supporting us.",
        })
        setLoading(false)

        return
      }

      const stripe = await getStripe()
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.session.id,
      })

      if (error) {
        console.warn("Error:", error)
      }
    }

    setLoading(false)
  }

  // console.log('allStripePrice', data.allStripePrice.nodes)
  // console.log('alldata', alldata)
  // console.log('product', product)

  return (
    <Layout location={location}>
      <Seo title="Donate" />
      <BgImage image={donateBgImg} className="w-100 h-100">
        <div id="donate" className="text-white">
          {/* <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-6 mb-5 mb-lg-0">
                <span
                  className="text-uppercase text-lightblue"
                  style={{ fontWeight: 700 }}
                >
                  Join Us
                </span>
                <h1 className="display-3 mb-5" style={{ fontWeight: 800 }}>
                  How You Can Help
                </h1>
                <div className="d-grid gap-4">
                  <p className="lead" style={{ fontWeight: 400 }}>
                    "Through charitable donations, the FLUO Foundation depends
                    on individuals like you to partner with us to transform
                    countless lives across the country. The FLUO Foundation
                    helps create programs and outreach to the mental health
                    community in our children, teens and young adults. We seek
                    to galvanize other nonprofits in alignment within their
                    scope, to focus on the youth in the mental health field.
                  </p>
                  <p className="lead" style={{ fontWeight: 400 }}>
                    Together we are amplifying a vibration that creates and
                    inspires a huge impact far beyond anything we could ever
                    imagine. Making a donation by clicking the button below will
                    go directly to helping a youth in need, one child at a
                    time."
                  </p>
                  <div className="p-4 rounded-25 bg-white">
                    <StaticImage
                      src="../images/donation-embed2.png"
                      alt="Donation placeholder image"
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </div>
                  <div className="mt-4">
                    <StaticImage
                      src="../images/heart-red-icon.svg"
                      alt="Heart red icon"
                      placeholder="blurred"
                      layout="constrained"
                    />
                    <span className="font-weight-bold lead d-block">
                      Thank you!
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="container w-100 m-auto" style={{ maxWidth: 700 }}>
            <div className="text-center">
              <span
                className="text-uppercase text-lightblue"
                style={{ fontWeight: 700 }}
              >
                SUPPORT OUR WORK
              </span>
              <h1 className="display-3 mb-5" style={{ fontWeight: 800 }}>
                Make a Donation
              </h1>
            </div>

            <p className="lead" style={{ fontWeight: 400 }}>
              {/* Through charitable donations, the FLUO Foundation depends on
              individuals like you to partner with us to transform countless
              lives across the country. The FLUO Foundation helps create
              programs and outreach to the mental health community in our
              children, teens and young adults. We seek to galvanize other
              nonprofits in alignment within their scope, to focus on the youth
              in the mental health field. */}
              Driven by a bold vision for the profound transformation in the
              mental health of young individuals, the FLUO Foundation relies on
              the charitable donations of those who are ready to rally behind
              our youth.
              <br />
              <br />
              {/* Together we are amplifying a vibration that creates and inspires a
              huge impact far beyond anything we could ever imagine. Making a
              donation by clicking the button below will go directly to helping
              a youth in need, one child at a time. */}
              By galvanizing our resources, especially reoccurring monthly
              donations, you will be joining a community of revolutionaries who
              are fostering the mental health of young individuals.
              Collectively, we don't stop at saving lives or preventing more
              trauma, we end the neglect so that we can transform their lives
              entirely.  We help our youth find their place in the world,
              empower them, and inspire them to live a life full of meaning and
              purpose. 
            </p>

            <div className="bg-white rounded p-3 p-md-5 text-dark mt-5">
              {errMsg?.type && (
                <div className={errMsg?.type} role="alert">
                  {errMsg.msg}
                </div>
              )}

              <div
                className="btn-group mb-3 w-100"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button btn-block"
                  className={`btn text-uppercase ${
                    type === "one_time" ? "btn-blue" : "btn-outline-blue"
                  }`}
                  onClick={() => setType("one_time")}
                >
                  one time
                </button>
                <button
                  type="button btn-block"
                  className={`btn text-uppercase ${
                    type === "recurring" ? "btn-blue" : "btn-outline-blue"
                  }`}
                  onClick={() => setType("recurring")}
                >
                  monthly
                </button>
              </div>

              <h5 className="text-dark">{`Choose a ${
                type === "one_time" ? "one-time" : "monthly"
              } amount`}</h5>

              <div className="d-flex flex-wrap align-items-center">
                {alldata
                  .sort((a, b) => b.unit_amount - a.unit_amount)
                  .map(item => {
                    if (!item.active || !item.product.active) return null

                    const selected = product?.id === item.id
                    return (
                      <button
                        style={{ marginRight: "1rem" }}
                        type="button"
                        className={`mb-2 btn ${
                          selected ? "btn-blue" : "btn-outline-secondary"
                        }`}
                        key={item.id}
                        onClick={() => setProduct(item)}
                      >
                        <CurrencyFormatter
                          currency={item.currency}
                          amount={item.unit_amount / 100}
                        />
                      </button>
                    )
                  })}
                <input
                  style={{ maxWidth: 200 }}
                  type="number"
                  className="mb-2 custom-form-control"
                  id="otherAmount"
                  placeholder="Other amount"
                  value={otherAmount}
                  onChange={e => setOtherAmount(e.target.value)}
                />
              </div>

              {/* <div className="form-group mb-3">
                <label htmlFor="otherAmount">Other</label>
                <input type="number" className="custom-form-control" id="otherAmount" value={otherAmount} onChange={e => setOtherAmount(e.target.value)} />
              </div> */}

              <div className="form-group mb-3">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  className="custom-form-control"
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="honor"
                  checked={honor}
                  onChange={e => setHonor(e.target.checked)}
                />
                <label className="form-check-label text-dark" htmlFor="honor">
                  Dedicate my donation in honor or memory of someone.
                </label>
              </div>
              {honor && (
                <div className="form-group mb-3">
                  <label htmlFor="honorName">Honoree</label>
                  <input
                    type="text"
                    className="custom-form-control"
                    id="honorName"
                    value={honorName}
                    onChange={e => setHonorName(e.target.value)}
                  />
                </div>
              )}
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="fee"
                  checked={fee}
                  onChange={e => setFee(e.target.checked)}
                />
                <label className="form-check-label text-dark" htmlFor="fee">
                  I'd like to help cover the transaction fee for my donation.
                </label>
              </div>

              <button
                className="btn btn-primary w-100 mt-4"
                onClick={redirectToCheckout}
              >
                {loading ? "Loading.." : "Donate"}
              </button>
            </div>

            <div className="mt-4 text-center">
              <StaticImage
                src="../images/heart-red-icon.svg"
                alt="Heart red icon"
                placeholder="blurred"
                layout="constrained"
              />
              <span className="font-weight-bold lead d-block">Thank you!</span>
            </div>
          </div>
        </div>
      </BgImage>
    </Layout>
  )
}

export default Donate

export const donatePageQuery = graphql`
  query donatePageQuery {
    donateBg: file(relativePath: { eq: "donate-bg.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    allStripePrice {
      nodes {
        active
        id
        unit_amount
        unit_amount_decimal
        product {
          id
          name
          type
          active
          object
        }
        currency
        type
      }
    }
  }
`
